<template>
  <div class="contact">
    <div class="contact-form">
      <input type="text">
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {

  },
};
</script>
