<template>
    <!-- <div id="nav">
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
      </div> -->

    <div class="main-container" :class="{loaded: isLoaded}">
      <div class="glow-load"></div>

      <div class="content-container">
      <router-view/>

      </div>
    </div>

</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');
#app {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
        text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    max-width: 100%;

}
.home {
  display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
        padding-bottom: 15%;
}
h1 {
  margin: 0;
}
body {
    background-color: #000;
    display: flex;
    height: 100vh;
    margin: 0;
    color: #fff;
    overflow: hidden;
    position: relative;
    padding: 0px 20px;
    justify-content: center;
        border-right: 10px inset #01a67d;
}

.cursor {
  cursor: url(/cursor.svg), pointer;
}


.main-container {
  display: flex;


    .content-container {
       display: flex;

      transform:translateY(400px);

      opacity: 0;
      transition: opacity 0.2s ease-out 0.5s, transform 0.8s cubic-bezier(0.52, 0.75, 0.28, 1) 0.2s;
    }



    .glow-load {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(-90deg, #01a67d 0%, #01a67d 69%, #0000 99%);
      width: 200vw;
      transform: translateX(0%);
      transition: transform 0.7s cubic-bezier(0.52, 0.75, 0.28, 1);

      z-index: 100;
    }
    &.loaded {
      .content-container {

      transform:translateY(0px);
      opacity: 1;
      }

      .glow-load {
        transform: translateX(100%);
      }
    }
}

.glow {
      position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #01a67d;
    pointer-events: none;
}

html {
    // line-height: 1.15;
    // -webkit-text-size-adjust: 100%;
    // font-size: 62.5%;

    // @media screen and (max-width: 992px) {
    //   font-size: 50%;
    // }
    overflow: hidden;
    user-select: none;
}

img {
  max-width: 100vw;
}
.subtitle {
    white-space: nowrap;
    font-size: 22px;
    line-height: 22px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    margin-top: -20px;

    @media screen and (max-width: 768px) {
      font-size: 18px;
      margin-top: -15px;
    }

    @media screen and (max-width: 300px) {
      font-size: 16px;
      margin-top: -12px;
    }
}

// #nav {
//     padding: 30px;
//     a {
//         font-weight: bold;
//         color: #2c3e50;
//         &.router-link-exact-active {
//             color: #42b983;
//         }
//     }
// }
</style>

<script>
export default {
  data() {
    return {
      isLoaded: false
    }
  },
  mounted() {
    setTimeout(() => this.isLoaded = true, 500);
  },
  methods: {
        sendMail: function () {
            const url = ' ';
            const {name, email, message} = this.contactFormData;
            const payload = {name, email, message};
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            })
                .then(() => {
                    this.success = true;
                    this.resetForm();
                })
                .catch(() => {
                    this.error = true;
                })
        },
        resetForm: function () {
            this.contactFormData = {
                name: '',
                email: '',
                message: '',
            };
        },
    },
}
</script>
