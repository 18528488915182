<template>
  <div class="home">
    <a href="mailto:yo@josephmatterson.com" target="_blank" title="Click to email">
      <h1><img alt="Joseph Matterson" src="../assets/logo.png"></h1>
    </a>
    <div class="subtitle">Full-stack Developer </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {

  },
};
</script>
